import styled from 'styled-components'
import { device } from '../../theme'

export const SectionWrapper = styled.div`
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: ${(props) => props.theme.colors.background};
  z-index: 5;

  @media ${device.small} {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .accordion__item {
    margin-bottom: 0;
  }

  .accordion__button {
    color: ${(props) => props.theme.colors.theme};
    font-family: ${(props) => props.theme.fontFamily.heading};
    font-weight: normal;
  }
`
