import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { Container, Row, Col } from '../../components/ui/wrapper'
import BoxLargeImage from '../../components/box-large-image/layout-two'
import Heading from '../../components/ui/heading'
import { SectionWrap, SwiperSection, List } from './style'
import { resolveUrlWithPrefix } from '../../utils/resolveUrl'

const PageSlider = ({
  sliderSettings,
  list,
  title,
  filter,
  limit,
  path,
  btnText,
  category,
  offset,
}) => {
  const renderThemeString = (themes) => {
    themes.map((theme) => <span>{theme.title}</span>)
  }

  const PageData = useStaticQuery(graphql`
    query AllSubThemePagesQuery {
      subthemes: allContentfulOnderwerp {
        edges {
          node {
            __typename
            id
            title
            slug
            image {
              fluid(maxWidth: 260, cropFocus: CENTER) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            themes {
              id
              title
            }
          }
        }
      }
    }
  `)

  const getItemList = () => {
    const items = PageData.subthemes.edges.map((edge) => edge.node)

    if (filter) {
      return items
        .filter((item) => {
          return item.themes && Array.isArray(item.themes) && item.themes.filter((currentTheme) => currentTheme.id === filter).length > 0
        })
        .slice(0, limit)
    } else if (list) {
      return list
    } else {
      return items.slice(0, limit)
    }
  }

  const itemList = getItemList()

  return (
    <>
      {itemList.length > 0 && (
        <SectionWrap className="container-xl">
          <Container>
            {itemList.length > 4 ? (
              <Row>
                <Col lg={12}>
                  {title && !offset && (
                    <Heading mt="40px" mb="-20px" as="h2">
                      {title}
                    </Heading>
                  )}

                  <SwiperSection offset={offset.toString()} {...sliderSettings}>
                    {itemList.map(
                      (item) => {
                        var {
                          id,
                          title,
                          image,
                          icon,
                          theme,
                          themes,
                          description,
                        } = item
                        return (
                          <div key={id} className="item">
                            <BoxLargeImage
                              imageSrc={image || icon}
                              title={title}
                              desc={
                                description && typeof description === 'string'
                                  ? description
                                  : undefined
                              }
                              btnText={btnText || title}
                              category={
                                theme && theme.title
                                  ? theme.title
                                  : themes && themes.lenght > 0
                                    ? renderThemeString(themes)
                                    : category
                              }
                              path={resolveUrlWithPrefix(item)}
                              smallIcon={icon}
                            />
                          </div>
                        )
                      },
                    )}
                  </SwiperSection>
                </Col>
              </Row>
            ) : (
              <List offset={offset.toString()}>
                {title && (
                  <Col lg={12}>
                    <Heading mb="26px" as="h2">
                      {title}
                    </Heading>
                  </Col>
                )}
                {itemList.map(
                  (item) => {
                    var {
                      id,
                      title,
                      image,
                      icon,
                      theme,
                      themes,
                      description,
                    } = item

                    return (
                      <Col key={id} md={6} lg={3}>
                        <BoxLargeImage
                          imageSrc={image || icon}
                          title={title}
                          desc={
                            description && typeof description === 'string'
                              ? description
                              : undefined
                          }
                          btnText={btnText || title}
                          category={
                            theme && theme.title
                              ? theme.title
                              : themes && themes.lenght > 0
                                ? renderThemeString(themes)
                                : category
                          }
                          path={resolveUrlWithPrefix(item)}
                          smallIcon={icon}
                        />
                      </Col>
                    )
                  },
                )}
              </List>
            )}
          </Container>
        </SectionWrap>
      )}
    </>
  )
}

PageSlider.propTypes = {
  sliderSettings: PropTypes.object,
  list: PropTypes.array,
  btnText: PropTypes.string,
  offset: PropTypes.bool,
  path: PropTypes.string,
  filter: PropTypes.string,
  limit: PropTypes.number,
  category: PropTypes.string,
}

PageSlider.defaultProps = {
  btnText: '',
  path: '',
  offset: false,
  sliderSettings: {
    slidesPerView: 1,
    loop: true,
    arrows: true,
    paginationStyle: {
      mt: '50px',
    },
    breakpoints: {
      1499: {
        slidesPerView: 4,
      },

      991: {
        slidesPerView: 4,
      },

      767: {
        slidesPerView: 2,
      },

      320: {
        slidesPerView: 1,
      },
    },
  },
}

export default PageSlider
