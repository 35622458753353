import React, { useState } from 'react'
import SEO from '../../components/seo'
import Layout from '../../containers/layout/layout'
import Header from '../../containers/header'
import Footer from '../../containers/footer'
import Hero from '../../components/hero'
import BoxImageLarge from '../../containers/elements/box-large-image/section-home'
import Events from '../../containers/events'
import Counters from '../../containers/elements/counters/section-two'
import PageSlider from '../../containers/page-slider'
import ParagraphSlider from '../../containers/paragraph-slider'
import QuoteSlider from '../../containers/quote-slider'
import Usps from '../../containers/usps'
import Heading from '../../components/ui/heading'
import { ContactCard } from '../../containers/layout/detail-layout'
import { Container, Row, Col } from '../../components/ui/wrapper'

const ThemeDetail = ({ location, pageContext }) => {
  const {
    id,
    type,
    title,
    description,
    image,
    color,
    paragraphContent,
    contact,
    quotes,
    usps,
  } = pageContext
  const [flyoutOpen, setFlyoutOpen] = useState(false)

  const managers =
    contact && contact.filter((person) => person.type === 'Bestuurder')
  const contacts =
    contact && contact.filter((person) => person.type !== 'Bestuurder')

  const flyoutHandler = () => {
    setFlyoutOpen((prevState) => !prevState)
  }

  return (
    <Layout location={location}>
      <SEO
        title={`Thema's: ${title}`}
        image={image?.file?.url}
        url={location.href}
        metadata={pageContext.metadata}
      />

      <Header
        transparent
        flyoutOpen={flyoutOpen}
        flyoutHandler={flyoutHandler}
      />
      <main id="main" className="site-wrapper-reveal">
        <Hero
          date={type}
          flyoutHandler={flyoutHandler}
          title={title}
          description={description.description}
          pageContext={pageContext}
          image={image}
          color={color}
          background={true}
          showLastLable={false}
        />

        <PageSlider
          filter={id}
          title="Opgaven"
          btnText="Bekijk dit onderwerp"
        />

        {paragraphContent && <ParagraphSlider content={paragraphContent} />}
        {usps && <Usps content={usps} />}
        <Counters color={color} themeFilter={title} />
        {quotes && <QuoteSlider quotes={quotes} />}

        <BoxImageLarge
          path="/actueel/publicaties"
          title="Publicaties"
          buttontext="publicaties"
          detailButtonText="Bekijk publicatie"
          type="article"
          filter={id}
        />

        <BoxImageLarge split={true} filter={id} />
        <Events title="Bijeenkomsten" filter={id} />

        {((contacts && contacts.length > 0) ||
          (managers && managers.length > 0)) && (
          <Container mt="80px" mb="80px">
            <Row>
              <Col md={6}>
                <Heading as="h3" mb="40px">
                  Meer informatie over dit thema? <br />
                  Neem dan contact op met de themaregisseur.
                </Heading>
              </Col>

              {managers && managers.length > 0 && (
                <Col md={contacts && contacts.length > 0 ? 3 : 6}>
                  <Heading as="h4" mb="25px" color="black">
                    Betrokken bestuurder
                  </Heading>

                  <Row>
                    {managers.map((manager) => (
                      <React.Fragment key={manager.id}>
                        <ContactCard
                          key={managers.id}
                          col={contacts && contacts.length > 0 ? 12 : 6}
                          style={{ marginBottom: '30px' }}
                          {...manager}
                        />
                      </React.Fragment>
                    ))}
                  </Row>
                </Col>
              )}

              {contacts && contacts.length > 0 && (
                <Col md={managers && managers.length > 0 ? 3 : 6}>
                  <Heading as="h4" mb="25px" color="black">
                    Contactpersoon
                  </Heading>

                  <Row>
                    {contacts.map((person) => (
                      <React.Fragment key={person.id}>
                        <ContactCard
                          key={person.id}
                          col={managers && managers.length > 0 ? 12 : 6}
                          style={{ marginBottom: '30px' }}
                          {...person}
                        />
                      </React.Fragment>
                    ))}
                  </Row>
                </Col>
              )}
            </Row>
          </Container>
        )}
      </main>
      <Footer />
    </Layout>
  )
}

export default ThemeDetail
