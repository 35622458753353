const ensurePrefix = (path, prefix = "/") => {
    if (!path) {
        return path
    }
    if (path.startsWith(prefix)) {
        return path
    }
    return prefix + path
}

const resolveUrl = (item) => {
    if (item?.__typename === "ContentfulThema") {
        return `/themas${ensurePrefix(item.url)}`
    }
    if (item?.__typename === "ContentfulOnderwerp") {
        return `/onderwerp${ensurePrefix(item.slug)}`
    }
    return ensurePrefix(item.slug) || item.url;
}

const resolveUrlWithPrefix = (item, prefix = undefined) => {
    if (prefix) {
        return `${prefix}${resolveUrl(item)}`
    }
    return resolveUrl(item)

}

module.exports = {
    resolveUrl,
    resolveUrlWithPrefix
}
