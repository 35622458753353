import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { Row, Col } from 'react-bootstrap'
import { SectionWrap } from './section.style'
import Counter from '../../../../components/counter/layout-two'
import { theme } from '../../../../theme'

const Section = ({ counterStyle, color, themeFilter }) => {
  const CounterData = useStaticQuery(graphql`
    query SmallCounterQuery {
      publications: allContentfulPublicatie {
        edges {
          node {
            themes {
              title
            }
          }
        }
        totalCount
      }

      townships: allContentfulGemeente(filter: { count: { eq: true } }) {
        totalCount
      }

      details: allContentfulBedrijf(
        filter: { id: { eq: "ca7bb2ca-5d8a-531e-a88e-80dc8c9e272b" } }
      ) {
        edges {
          node {
            people
            agenda
          }
        }
      }
    }
  `)

  const publications =
    CounterData.publications.totalCount > 0 &&
    CounterData.publications.edges.map((edge) => edge.node)
  const filteredPublications = publications
    .map((item) => {
      return item.themes &&
        item.themes.filter((theme) => theme.title === themeFilter).length
        ? item
        : false
    })
    .filter((result) => result)
  const publicationsCount =
    filteredPublications.length || CounterData.publications.totalCount
  const townships = CounterData.townships.totalCount
  const { people, agenda } = CounterData.details.edges[0].node

  return (
    <SectionWrap color={color} className="container-xl">
      <Row>
        <Col md={3} sm={6}>
          <Counter {...counterStyle} countTo={townships} text="Gemeenten" />
        </Col>
        <Col md={3} sm={6}>
          <Counter {...counterStyle} countTo={people} text="Mensen" />
        </Col>
        <Col md={3} sm={6}>
          <Counter {...counterStyle} countTo={agenda} text="Agenda" />
        </Col>
        <Col md={3} sm={6}>
          <Counter
            {...counterStyle}
            countTo={publicationsCount}
            text="Publicaties"
          />
        </Col>
      </Row>
    </SectionWrap>
  )
}

Section.propTypes = {
  counterStyle: PropTypes.object,
  color: PropTypes.string,
  themeFilter: PropTypes.string,
}

Section.defaultProps = {
  color: theme.colors.theme,
  themeFilter: 'none',
  counterStyle: {
    layout: 2,
    mb: '30px',
  },
}

export default Section
