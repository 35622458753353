import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Heading from '../../components/ui/heading'
import Text from '../../components/ui/text'
import SwiperSlider from '../../components/ui/swiper'
import { Container, Row, Col } from '../../components/ui/wrapper'

const ParagraphSlider = ({ content }) => {
  const Wrapper = ({ children }) =>
    Array.isArray(content) && content.length > 1 ? (
      <SwiperSlider slidesPerView={1}>{children}</SwiperSlider>
    ) : (
      <>{children}</>
    )

  return (
    <Container mb="80px" mt="80px">
      <Wrapper>
        {content &&
          content.map(({ id, title, image, content }) => (
            <div key={`content-slider-${id}`}>
              <Row alignitems="center">
                {image && (
                  <Col md={6}>
                    <Img fluid={image.fluid} alt={title || image.title} />
                  </Col>
                )}

                {(title || content) && (
                  <Col md={{ span: 5, offset: 1 }} pb="30px">
                    {title && (
                      <Heading as="h2" mb="13px" mt="30px">
                        {title}
                      </Heading>
                    )}
                    {content && content.json && <Text json={content.json} />}
                  </Col>
                )}
              </Row>
            </div>
          ))}
      </Wrapper>
    </Container>
  )
}

ParagraphSlider.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.object,
      image: PropTypes.object,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.object,
        image: PropTypes.object,
      }),
    ),
  ]),
}

export default ParagraphSlider
