import styled, { css } from 'styled-components'
import Swiper from '../../components/ui/swiper'
import { Row } from '../../components/ui/wrapper'
import { device } from '../../theme'

export const SectionWrap = styled.section`
  position: relative;
  padding-top: 0;
  padding-bottom: 70px;
  background-color: ${(props) => props.theme.colors.background};
  z-index: 5;

  @media ${device.large} {
    padding-top: 70px;
  }

  @media ${device.medium} {
    .col-lg-3,
    .col-md-6 {
      margin-bottom: 30px;
    }
  }

  @media ${device.small} {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .swiper-slide.item {
    height: auto;
  }
`

export const SwiperSection = styled(Swiper)`
  padding: 40px 0 0;

  ${(props) =>
    props.offset === 'true' &&
    css`
      padding: 0;

      @media ${device.largeOnly} {
        margin: -80px 0 0;
        padding: 50px 40px 0;
        background-color: ${(props) => props.theme.colors.background};
      }

      @media (min-width: 1280px) {
        margin: -80px -55px 0;
      }
    `}

  .swiper-btn {
    top: 50%;
  }
`

export const List = styled(Row)`
  padding: 40px 0 0;

  ${(props) =>
    props.offset === 'true' &&
    css`
      padding: 0;

      @media ${device.largeOnly} {
        position: relative;
        top: -80px;
        margin: 0 0 -80px;
        padding: 50px 40px 0;
        background-color: ${(props) => props.theme.colors.background};
      }

      @media (min-width: 1280px) {
        margin: 0 -55px -80px;
      }
    `}
`
