import React from 'react'
import PropTypes from 'prop-types'
import Heading from '../../components/ui/heading'
import Text from '../../components/ui/text'
import AccordionWrap from '../../components/ui/accordion'
import { Container, Row, Col } from '../../components/ui/wrapper'
import { SectionWrapper } from './style'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

const Usps = ({ content }) => {
  return (
    <SectionWrapper className="container-xl">
      <Container>
        <Row>
          <Col md={12}>
            <Heading as="h3" textalign="center" mb="15px">
              Versterking met focus
            </Heading>
            <Text as="div" align="center" mb="50px">
              We hebben de volgende hoofdopgaven.
            </Text>
          </Col>
          <Col md={12}>
            <AccordionWrap layout="two">
              <Accordion allowZeroExpanded preExpanded={[0]}>
                {content &&
                  content.map(({ id, title, content }) => (
                    <AccordionItem id={id} key={`content-accordion-${id}`}>
                      <AccordionItemHeading>
                        <AccordionItemButton>{title}</AccordionItemButton>
                      </AccordionItemHeading>

                      <AccordionItemPanel>
                        {content && content.json ? (
                          <Text json={content.json} />
                        ) : (
                          ''
                        )}
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
              </Accordion>
            </AccordionWrap>
          </Col>
        </Row>
      </Container>
    </SectionWrapper>
  )
}

Usps.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.object,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.object,
      }),
    ),
  ]),
}

export default Usps
