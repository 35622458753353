import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMedia } from 'react-use-media'
import { FiDownload } from 'react-icons/fi'
import ScrollToTop from '../../components/ui/scroll-to-top'
import Img from 'gatsby-image'
import SEO from '../../components/seo'
import Header from '../../containers/header'
import Footer from '../../containers/footer'
import Hero from '../../components/hero'
import Heading from '../../components/ui/heading'
import Text from '../../components/ui/text'
import Roles from '../../containers/roles'
import Timeline from '../../components/ui/timeline'
import Anchor from '../../components/ui/anchor'
import Button from '../../components/ui/button'
import List, { ListItem } from '../../components/ui/list'
import { SocialShare } from '../../components/ui/social'
import { Row, Col } from '../../components/ui/wrapper'
import { slugify } from '../../utils/utilFunctions'
import { size } from '../../theme'

// import '../../assets/fonts/oswald/oswald.css'
import '../../assets/fonts/dia/dia.css'
import '../../assets/fonts/opensans/opensans.css'
import '../../assets/css/bootstrap.css'

import {
  SectionWrap,
  ContentWrap,
  IntroText,
  Content,
  ContactWrap,
  Card,
  CardPersonal,
  CardInfo,
  HeaderSocial,
  FooterSocial,
  ThemeChips,
  Chip,
  Downloads,
  Subpages,
} from './detail-layout.style'

export const ContactCard = ({
  name,
  title,
  township,
  phone,
  mobile,
  email,
  image,
  col = 4,
  ...rest
}) => (
  <Col md={col} {...rest}>
    <Card col={col}>
      <CardPersonal>
        {image && image.fixed && <Img fixed={image.fixed} alt={name} />}

        <h4>{name}</h4>
        {title && <div> {title} </div>}
      </CardPersonal>

      <CardInfo>
        {township && <div> {township} </div>}
        {email && (
          <div>
            <a href={`mailto: ${email}`}>{email}</a>
          </div>
        )}
        {phone && (
          <div>
            <a href={`tel: ${phone}`}>{phone}</a>
          </div>
        )}
        {mobile && (
          <div>
            <a href={`tel: ${mobile}`}>{mobile}</a>
          </div>
        )}
      </CardInfo>
    </Card>
  </Col>
)

const DetailLayout = ({ children, location, pageData, pageContext }) => {
  const [flyoutOpen, setFlyoutOpen] = useState(false)

  const {
    title,
    subTitle,
    description,
    content,
    date,
    shortDate,
    longDate,
    startTime,
    duration,
    price,
    geoLocation,
    image,
    contact,
    themes,
    roles,
    qa,
    downloads,
    subpages,
  } = pageData

  const isMobile = useMedia({ maxWidth: size.xxsmall })
  const managers =
    contact && contact.filter((person) => person.type === 'Bestuurder')
  const contacts =
    contact && contact.filter((person) => person.type !== 'Bestuurder')
  const endDate = duration ? new Date(date).getTime() + duration * 60000 : false
  const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
  })

  const flyoutHandler = () => {
    setFlyoutOpen((prevState) => !prevState)
  }

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://cdn.embedly.com/widgets/platform.js'
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <main id="main" className="site-wrapper-reveal">
      <SEO
        title={pageContext.type ? `${pageContext.type}: ${title}` : title}
        description={description ? description.description : subTitle}
        image={image?.file?.url}
        type="article"
        metadata={pageContext.metadata}
      />
      <Header
        transparent
        flyoutOpen={flyoutOpen}
        flyoutHandler={flyoutHandler}
      />

      <Hero
        flyoutHandler={flyoutHandler}
        title={title}
        description={subTitle}
        image={image}
        date={shortDate || date || pageContext.categories[0]}
        pageContext={pageContext}
        showLastLable={false}
        color={pageContext.color ? pageContext.color : undefined}
        dot
      />

      <SectionWrap className="container-xl">
        <ContentWrap color={pageContext.color}>
          <HeaderSocial>
            <SocialShare
              url={location}
              title={title}
              description={description ? description.description : ''}
              print={!isMobile}
            />
          </HeaderSocial>

          {!pageContext.timeline && (
            <>
              <IntroText color={pageContext.color}>
                {description &&
                  description.childMarkdownRemark &&
                  description.childMarkdownRemark.html && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: description.childMarkdownRemark.html,
                      }}
                    />
                  )}

                {description &&
                  description.description &&
                  !description.childMarkdownRemark && (
                    <div>{description.description}</div>
                  )}

                {!pageContext.timeline && pageData.timeline && (
                  <Button
                    to={`${pageContext.parentPath}/${pageContext.slug}/detail`}
                    mt="25px"
                  >
                    Bekijk onze tijdlijn
                  </Button>
                )}
              </IntroText>

              <Content color={pageContext.color}>
                {content && content.childMarkdownRemark && (
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: content.childMarkdownRemark.html
                        .replace(
                          '<table>',
                          '<table class="table table-striped table-responsive">',
                        )
                        .replace('<thead>', '<thead class="thead-dark">'),
                    }}
                  />
                )}

                {content && content.json && <Text json={content.json} />}

                {roles && <Roles content={roles} color={pageContext.color} />}
                {qa && (
                  <Roles
                    showTitle={false}
                    content={qa}
                    color={pageContext.color}
                  />
                )}

                {pageContext.type === 'Agenda' && (
                  <Text as="div" mt="30px">
                    <h3>Datum</h3>
                    <p>
                      {longDate.charAt(0).toUpperCase() + longDate.slice(1)}
                      <br />
                      {startTime}
                      {endDate
                        ? ` - ${new Date(endDate).toLocaleTimeString('nl-NL', {
                            hour12: false,
                            hour: '2-digit',
                            minute: '2-digit',
                          })}`
                        : ''}
                      .
                    </p>

                    <h3>Locatie</h3>
                    <p>
                      {geoLocation ? geoLocation : 'Online'}
                      <br />
                    </p>

                    {price && (
                      <>
                        <h3>Prijs</h3>
                        <p>{formatter.format(price)}</p>
                      </>
                    )}
                  </Text>
                )}

                {downloads && (
                  <Downloads>
                    <h3>Download</h3>
                    <List layout="icon" className="downloads">
                      {downloads.map(({ id, title, file }) => (
                        <ListItem key={`list-item-${id}`}>
                          <a
                            key={`download-${id}`}
                            href={file.url}
                            target="_blank"
                            rel="noreferrer"
                            download
                            title={`Download de ${title} ${
                              file.contentType
                                ? file.contentType.split('/')[1]
                                : ''
                            }`}
                          >
                            <span className="icon">
                              <FiDownload role="img" />
                            </span>
                            <span>{title}</span>
                          </a>
                        </ListItem>
                      ))}
                    </List>
                  </Downloads>
                )}

                {subpages && (
                  <Subpages>
                    {subpages.map(
                      ({
                        id,
                        title,
                        headerTitel,
                        headerSubtitel,
                        description,
                        name,
                        url,
                      }) => (
                        <Col key={id} md={6}>
                          <Heading as="h4">{headerTitel || title}</Heading>

                          {headerSubtitel && <Text>{headerSubtitel}</Text>}

                          {!headerSubtitel && description && (
                            <Text>{description.description}</Text>
                          )}

                          <Anchor
                            path={
                              url
                                ? url
                                : location.pathname.endsWith('/')
                                ? `${location.pathname}${slugify(name)}`
                                : `${location.pathname}/${slugify(name)}`
                            }
                            aria-label={
                              url
                                ? `Bekijk de website van ${title} op ${url}`
                                : `Lees meer over ${headerTitel || title}`
                            }
                          >
                            {url ? 'Bekijk de website' : 'Lees meer'}
                          </Anchor>
                        </Col>
                      ),
                    )}
                  </Subpages>
                )}
              </Content>
            </>
          )}

          {pageContext.timeline && <Timeline items={pageData.timeline} />}

          {((contacts && contacts.length > 0) ||
            (managers && managers.length > 0)) && (
            <ContactWrap color={pageContext.color}>
              <Row>
                {managers && managers.length > 0 && (
                  <Col md={12}>
                    <h3>Betrokken bestuurder</h3>

                    <Row>
                      {managers.map((manager) => (
                        <React.Fragment key={manager.id}>
                          <ContactCard
                            key={managers.id}
                            style={{ marginBottom: '30px' }}
                            {...manager}
                          />
                        </React.Fragment>
                      ))}
                    </Row>
                  </Col>
                )}

                {contacts && contacts.length > 0 && (
                  <Col md={12}>
                    <h3>Contactpersoon</h3>

                    <Row>
                      {contacts.map((person) => (
                        <React.Fragment key={person.id}>
                          <ContactCard
                            key={person.id}
                            style={{ marginBottom: '30px' }}
                            {...person}
                          />
                        </React.Fragment>
                      ))}
                    </Row>
                  </Col>
                )}
              </Row>
            </ContactWrap>
          )}

          {pageContext.parentPath && themes && themes.length > 0 && (
            <ThemeChips>
              <div>Thema:</div>

              {themes.map((theme) => (
                <Chip
                  key={`theme-chip-${theme.id}`}
                  href={`${pageContext.parentPath}/${slugify(theme.title)}`}
                >
                  {theme.title}
                </Chip>
              ))}
            </ThemeChips>
          )}

          <FooterSocial>
            <SocialShare
              url={location}
              title={title}
              description={description ? description.description : ''}
              print={!isMobile}
            />
          </FooterSocial>
        </ContentWrap>
      </SectionWrap>

      {children}

      <Footer />
      <ScrollToTop />
    </main>
  )
}

DetailLayout.propTypes = {
  transition: PropTypes.bool,
  children: PropTypes.node,
  pageData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.object.isRequired,
    content: PropTypes.object,
    date: PropTypes.string,
    shortDate: PropTypes.string,
    longDate: PropTypes.string,
    startTime: PropTypes.string,
    duration: PropTypes.number,
    price: PropTypes.number,
    geoLocation: PropTypes.string,
    image: PropTypes.object.isRequired,
    contact: PropTypes.array,
  }),
}

export default DetailLayout
